var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-container', [_c('b-row', _vm._l(_vm.consultantList, function (consultant, index) {
    return _c('b-col', {
      key: consultant.hb_consultant_id,
      attrs: {
        "lg": "4",
        "md": "6",
        "clos": "12"
      }
    }, [_c('b-card', {
      staticClass: "mb-2",
      attrs: {
        "bg-variant": "dark",
        "text-variant": "white"
      }
    }, [_c('b-card-img', {
      staticStyle: {
        "object-fit": "cover"
      },
      attrs: {
        "src": consultant.picture,
        "height": "300px"
      }
    }), _c('b-card-text', {
      staticClass: "mt-2"
    }, [_c('p'), _c('h3', [_vm._v(_vm._s(consultant.english_name))]), _c('p'), _c('p', [_c('span', [_vm._v(_vm._s(consultant.nationality))]), _c('span', {
      staticClass: "float-right"
    }, [_vm._v(_vm._s(consultant.score))])]), _c('hr'), _c('p', {
      ref: `card_${index}`,
      refInFor: true,
      staticClass: "text-truncate text-justify hover-content",
      on: {
        "click": function ($event) {
          return _vm.showConsultantIntroduction(index);
        }
      }
    }, [_vm._v(_vm._s(consultant.self_introduction))])]), _c('b-button', {
      staticClass: "float-right",
      attrs: {
        "variant": "primary"
      },
      on: {
        "click": function ($event) {
          return _vm.redirectToSchedule(consultant.hb_consultant_id);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t('bookClass')))])], 1)], 1);
  }), 1)], 1), _c('router-view')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template lang="pug">
  div
    b-container
      b-row
        b-col(v-for="(consultant, index) in consultantList" :key="consultant.hb_consultant_id" lg="4" md="6" clos="12")
          b-card.mb-2(bg-variant="dark" text-variant="white")
            b-card-img(:src="consultant.picture" height="300px" style="object-fit: cover;")
            b-card-text.mt-2
              p
                h3 {{ consultant.english_name }}
              p
                span {{ consultant.nationality }}
                span.float-right {{ consultant.score }}
              hr
              p.text-truncate.text-justify.hover-content(:ref="`card_${index}`" @click="showConsultantIntroduction(index)") {{ consultant.self_introduction }}
            b-button.float-right(variant="primary" @click="redirectToSchedule(consultant.hb_consultant_id)") {{ $t('bookClass') }}
    router-view
</template>

<script>
// import lioshutanApi from '@/api';
import i18n from '@/views/dojo/bookClass/requireConsultant/lang';
export default {
  name: 'RequireConsultant',

  i18n,

  data() {
    return {
      // 顧問資料須從後端接API 目前為假資料
      consultantList: [
        {
          hb_consultant_id: 4,
          picture: 'https://cdn.wuwow.tw/consultants/pictures/4_20181228212949.JPG',
          english_name: 'David Flint',
          nationality: 'Canadian',
          self_introduction:
            'I am a teacher, actor, and musician in Taiwan. I have been in Taiwan for 21 years, and it is my home now. Everyone asks me why I came to Taiwan, but the real question to ask is why do I stay in Taiwan. I love the people! And I am so lucky because all my work is for and with people.',
          suggestion:
            'Everyone in Taiwan studies English. The government provides classrooms, teachers, books, tests, and grades.....but no speaking. Almost everyone I meet knows enough English to talk about many things, but no experience so it is difficult. My advice to students is to talk as much as you can to make all that study worthwhile! Talk as much as you can and make a better future!',
          preference: null,
          evaluation_count: 41,
          score: 97.05,
          score_for_sort: 97.05,
          interested_topic: ['日常生活 Daily Life'],
          class_count: 2500,
        },
        {
          hb_consultant_id: 7,
          picture: 'https://cdn.wuwow.tw/consultants/pictures/7_20210913181728.png',
          english_name: 'Kai Yang',
          nationality: 'Taiwanese',
          self_introduction:
            'Hi everyone, my name is Kai. As a bilingual teacher, I am capable of helping students from lower level to upper intermediate. I am very easy going and talkative. Traveling and playing music together compose a great part of me. Helping students also teaches me how to solve their doubts in a short time. I hope my  2 years experience in Australia can help you to know a bit more about Western culture.',
          suggestion:
            'Learning English is never a life-time goal. Please do use this useful language as a tool after knowing how to deliver your thoughts. Find your potential, stay hungry and keep learning! May all your dreams come true soon.',
          preference: 1,
          evaluation_count: 44,
          score: 97.95,
          score_for_sort: 97.95,
          interested_topic: [
            '文化 Culture',
            '日常生活 Daily Life',
            '娛樂 Entertainment',
            '基礎 Foundation',
            '新聞 News',
            '科技 Technology',
            '旅遊 Travel',
          ],
          class_count: 5481,
        },
        {
          hb_consultant_id: 12,
          picture: 'https://cdn.wuwow.tw/consultants/pictures/12_20200731184639.png',
          english_name: 'Gregg Tan',
          nationality: 'Filipino',
          self_introduction:
            "I'm a dynamic English teacher with 15+ years experience teaching English in cram schools and in online platforms with a dedication to correct, help and encourage students to speak and express themselves in their second language. I'm an advertising arts graduate who enjoys doing portraiture, listening to ballads, watching movies, and sometimes cooking. I love to sit and chat over a cup of coffee.",
          suggestion:
            'Always try to express your thoughts and feelings in a complete sentence in order for your listeners to understand you easily and for teachers to be able to check your grammar, improve your sentences, and suggest words/expressions/phrases to make your sentences clearer and better.',
          preference: 1,
          evaluation_count: 95,
          score: 99.74,
          score_for_sort: 99.74,
          interested_topic: [
            '商業 Business',
            '文化 Culture',
            '日常生活 Daily Life',
            '基礎 Foundation',
            '新聞 News',
            '科技 Technology',
            '旅遊 Travel',
            '食物/餐廳 Food / Restaurant',
            '健康 Health',
            '多益 TOEIC',
          ],
          class_count: 8139,
        },
        {
          hb_consultant_id: 20,
          picture: 'https://cdn.wuwow.tw/consultants/pictures/20_20190530155947.jpg',
          english_name: 'Sharon Hsiao',
          nationality: 'Taiwanese',
          self_introduction:
            'My name is Sharon. I am Taiwanese-Canadian. I majored in international business and got make-up artistry certification in Canada. I am also a certified ESL teacher, I got my TESOL certification in 2010. I have been teaching ESL online for more than 10 years. I am good at prompting and encouraging the students who are beginners and afraid to speak up. I like to use pictures and gestures to guide students, to make the class more relaxing and fun. Still too shy to speak English? Don’t be hesitated, come and join my class.',
          suggestion:
            'Want to learn English in a fun way? Choose your favorite topics such as travel, news, daily life, technology ...etc to start your class with us. Of course, with the topic you are interested, you will have the motivation to learn English. In the class, don’t be hesitated to speak up and share your opinions. Never worry to make any mistakes in the class because practice makes perfect.',
          preference: 1,
          evaluation_count: 92,
          score: 97.93,
          score_for_sort: 97.93,
          interested_topic: [
            '商業 Business',
            '文化 Culture',
            '日常生活 Daily Life',
            '娛樂 Entertainment',
            '基礎 Foundation',
            '旅遊 Travel',
            '食物/餐廳 Food / Restaurant',
            '健康 Health',
          ],
          class_count: 6658,
        },
        {
          hb_consultant_id: 21,
          picture: 'https://cdn.wuwow.tw/consultants/pictures/21_20210204233450.png',
          english_name: 'Stefan Costilow',
          nationality: 'American',
          self_introduction:
            "There is nothing I'm not interested in, seriously. No matter what subject we're on, I can talk about it, and I'll be very interested to get your viewpoints, especially if you know more about it than me!",
          suggestion:
            "Never, ever be afraid to push me, argue with me, or present an alternate view. I might be the class teacher, but if you know what you're talking about in re the class subject, then I want to hear it! More conversation please!",
          preference: 1,
          evaluation_count: 43,
          score: 98.19,
          score_for_sort: 98.19,
          interested_topic: [
            '商業 Business',
            '文化 Culture',
            '日常生活 Daily Life',
            '娛樂 Entertainment',
            '基礎 Foundation',
            '新聞 News',
            '科技 Technology',
            '旅遊 Travel',
            '食物/餐廳 Food / Restaurant',
            '健康 Health',
            '托福 TOEFL',
            '多益 TOEIC',
            '雅思 IELTS',
          ],
          class_count: 3042,
        },
        {
          hb_consultant_id: 1013,
          picture: 'https://cdn.wuwow.tw/consultants/pictures/1013_20180213124027.jpg',
          english_name: 'Popin Chang',
          nationality: 'Taiwanese',
          self_introduction:
            'Language enthusiast and nature lover. Currently based in Taipei, Taiwan.',
          suggestion:
            'English is never just about text books. Read a novel or watch a movie. You can always make learning English fun in your own way!',
          preference: null,
          evaluation_count: 49,
          score: 99.61,
          score_for_sort: 99.61,
          interested_topic: [
            '文化 Culture',
            '日常生活 Daily Life',
            '娛樂 Entertainment',
            '旅遊 Travel',
            '食物/餐廳 Food / Restaurant',
            '托福 TOEFL',
            '多益 TOEIC',
          ],
          class_count: 4382,
        },
        {
          hb_consultant_id: 1014,
          picture: 'https://cdn.wuwow.tw/consultants/pictures/1014_20180119185739.JPG',
          english_name: 'Emily Lu',
          nationality: 'British',
          self_introduction:
            "I’m Emily and I have been teaching English for more than 10 years. One of my strengths as an English teacher is creating a student-centered learning environment which focuses more on students' participation as well as their needs. I like to get students engaged in conversations and create situations that allows them to make connections to their ideas. Students are very welcome to share their thoughts and personal experiences during class. I currently live in England, so if necessary, I would also like to share my experiences of living abroad. I believe that learning a language should be an enjoyable process. For this reason, I try to build rapport with students and make each class stress-free. Join me and enjoy learning with me.",
          suggestion:
            'For students who want to improve their speaking ability, try to grasp every opportunity to speak out and not to be afraid of making mistakes. Whenever you learn a new word, think about how you can make a sentence by using it or how you can relate this word in daily life. When you try out a new word in your speaking, you are activating the word and therefore you will be able to remember this word in the long-term. For beginners, if you can’t find the words to express yourself, just listen as much as possible and when you are ready, start speaking, and have fun!. Constant practice is key in becoming fluent in English. Believe in yourself and you will succeed.',
          preference: 1,
          evaluation_count: 0,
          score: 0,
          score_for_sort: 0,
          interested_topic: [
            '文化 Culture',
            '日常生活 Daily Life',
            '娛樂 Entertainment',
            '基礎 Foundation',
            '旅遊 Travel',
            '食物/餐廳 Food / Restaurant',
            '健康 Health',
          ],
          class_count: 5524,
        },
        {
          hb_consultant_id: 1044,
          picture: 'https://cdn.wuwow.tw/consultants/pictures/1044_20200727164636.png',
          english_name: 'Dayling Shen',
          nationality: 'Taiwanese',
          self_introduction:
            "I'm a South African born Taiwanese who grew up in South Africa and is now residing in Taiwan. I'm currently a full-time online English Teacher, teaching from children to adults. I have over 4 years of teaching experience and have been teaching online for almost 3 years, but I really fell in love with teaching online, where the classes are small, and usually one on one. You can really get to know your students better and help them solve the problems that they may come across when learning English.",
          suggestion:
            '10 Tips for ESL Learners\n\n1. Surround yourself with English\n2. Make English friends\n3. Find study partners\n4. Use authentic materials \n5. Get online\n6. Set yourself realistic goals\n7. Listen to real English\n8. Find fun ways to learn new words\n9. Learn about the culture\n10. Whatever you do, have fun!\n\nPractice, practice and practice whenever you can.',
          preference: 1,
          evaluation_count: 28,
          score: 97.96,
          score_for_sort: 97.96,
          interested_topic: [
            '文化 Culture',
            '日常生活 Daily Life',
            '娛樂 Entertainment',
            '基礎 Foundation',
            '新聞 News',
            '科技 Technology',
            '旅遊 Travel',
            '食物/餐廳 Food / Restaurant',
            '健康 Health',
            '多益 TOEIC',
            '雅思 IELTS',
          ],
          class_count: 6299,
        },
        {
          hb_consultant_id: 1066,
          picture: 'https://cdn.wuwow.tw/consultants/pictures/1066_20210704111414.png',
          english_name: 'Savannah Chiang',
          nationality: 'Taiwanese',
          self_introduction:
            "Hi, it's Savannah. I was born in Changhua. I like cats and dogs. I love to sing and play basketball! In my free time, I like to hang out with my family or make dinner for them. It's my pleasure to meet you!",
          suggestion:
            "It's not easy to learn a new language, but it's not that hard if you spend time on it! You can find your favorite way to learn it. If you love to sing, you can sing English songs. If you like to watch movies, try to watch it without Chinese subtitle! Feel free to ask for help if needed!",
          preference: null,
          evaluation_count: 8,
          score: 99,
          score_for_sort: 0,
          interested_topic: [
            '日常生活 Daily Life',
            '旅遊 Travel',
            '食物/餐廳 Food / Restaurant',
          ],
          class_count: 714,
        },
        {
          hb_consultant_id: 1076,
          picture: 'https://cdn.wuwow.tw/consultants/pictures/1076_20180423153716.jpg',
          english_name: 'Tony Chang',
          nationality: 'Taiwanese',
          self_introduction:
            "Topics:\n1. Speaking/conversation\n2. TOEIC\n3. Grammar\n4. Vocabularies\n5. Your personal request. \n\nHaving problems with communicating with your foreign colleagues? \nFailed in presentation when it's show time?\nNeed somebody to practice for your interview?\nFed up with boring classes that only talks about memorizing vocabularies and grammar?\n\nOR\n\nAre you just getting started with English and having trouble making solid sentences?\n\nHi, Tony here.\nAn English instructor, a baseball fan and a decent cook who likes to interact with people.\n\nA little secret about me is that I am a mushroom hater : )\n\nFor beginners, I will focus on building your confidence by  helping you build solid sentences.\nFor advanced learners, I focus on sharing:\na. Words that are commonly confused by English learners\nb. Useful phrases for you\nc.  Things that are outside of material\n\nLet's dive into the world of English together!",
          suggestion:
            '-Speak as much as you can without worrying about making mistakes.\n-It is better to make mistakes in class instead of making mistakes when it is show time, right?\n-Be a sponge and get ready to enjoy English.',
          preference: 1,
          evaluation_count: 24,
          score: 99.42,
          score_for_sort: 0,
          interested_topic: [
            '商業 Business',
            '文化 Culture',
            '日常生活 Daily Life',
            '娛樂 Entertainment',
            '科技 Technology',
            '旅遊 Travel',
            '食物/餐廳 Food / Restaurant',
            '健康 Health',
            '多益 TOEIC',
          ],
          class_count: 4750,
        },
      ],
    };
  },

  created() {
    // this.getConsultantList();
  },

  methods: {
    // async getConsultantList() {
    //   const response = await lioshutanApi.consultant.getConsultantList();
    //   this.consultantList = response.data.data.hb_consultant;
    // },
    showConsultantIntroduction(cardIndex){
      const firstIndex = 0, truncateClass = 'text-truncate', nonexistentValue = -1;
      const ref = 'card_' + cardIndex;
      if (this.$refs[ref][firstIndex].classList.value.indexOf(truncateClass) > nonexistentValue){
        this.$refs[ref][firstIndex].classList.remove(truncateClass);
      } else {
        this.$refs[ref][firstIndex].classList.add(truncateClass);
      }
    },

    redirectToSchedule(hbConsultantId){
      const schedulePath = `/dojo/bookClass/requireConsultant/schedule/${hbConsultantId}`;
      this.$router.push(schedulePath);
    },
  },
};
</script>

<style>
.hover-content:hover{
  cursor: pointer;
  color: #B0B0B0;
}
</style>

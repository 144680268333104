export default {
  messages: {
    tw: {
      'bookClass': '預約課程',
    },
    en: {
      'bookClass': 'Book Class',
    },
  },
};
